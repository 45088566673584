import React from "react";
import logo from "../assets/icons/logo.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const LetsTalk = () => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  return (
    <>
      <div className="lets-talk ">
        <div className="primary-wrapper">
          <div className="logo-slider">
            <img src={logo} alt="" />
          </div>

          <div
            className="primary-content pointer"
            onClick={() => navigate("/contact-us")}
          >
            <h6 className="text-uppercase text-center">{t('letsTalk.workWithUs')}</h6>
            <h3 className="text-center">
              {t('letsTalk.excitedMessagePart1')} <br /> {t('letsTalk.excitedMessagePart2')}
            </h3>
          </div>
          <div className="hovered-content  ">
            <h1>{t('letsTalk.heading')}</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default LetsTalk;
