import React from "react";
import "./servicepagecards.scss";
import Servicecard from "../servicecard/servicecard";
import Animatedheading from "../animatedheading/animatedheading";
import { useTranslation } from "react-i18next";
function Servicepagecards() {
  const {t} = useTranslation()
  const data = [
    {
      imgsrc: "/assets/images/servicepagecards/AI.webp",
      heading: t('utils.heading1'),
      para: t('utils.para1'),
      number: t('utils.number1'),
    },
    {
      imgsrc: "/assets/images/servicepagecards/web.webp",
      heading: t('utils.heading2'),
      para: t('utils.para2'),
      number: t('utils.number2'),
    },
    {
      imgsrc: "/assets/images/servicepagecards/mobile-app.webp",
      heading: t('utils.heading3'),
      para: t('utils.para3'),
      number: t('utils.number3'),
    },
    {
      imgsrc: "/assets/images/servicepagecards/ui.webp",
      heading: t('utils.heading4'),
      para: t('utils.para4'),
      number: t('utils.number4'),
    },
    {
      imgsrc: "/assets/images/servicepagecards/2d.webp",
      heading: t('utils.heading5'),
      para: t('utils.para5'),
      number: t('utils.number5'),
    },
    {
      imgsrc: "/assets/images/servicepagecards/game-dev.webp",
      heading: t('utils.heading6'),
      para: t('utils.para6'),
      number: t('utils.number6'),
    },
    {
      imgsrc: "/assets/images/servicepagecards/sales.webp",
      heading: t('utils.heading7'),
      para: t('utils.para7'),
      number: t('utils.number7'),
    },
    {
      imgsrc: "/assets/images/servicepagecards/quality.webp",
      heading: t('utils.heading8'),
      para: t('utils.para8'),
      number: t('utils.number8'),
    },
  ];
  
  return (
    <>
      <section className="servicepagecards-sec">
        <div className="heading">
          <Animatedheading
            outer={t('utils.explore')}
            innerfirst={t('utils.innerfirst')}
            innersecond={t('utils.innersecond')}
          />
          <p className="detail-para">
          {t('utils.detailPara')}
          </p>
        </div>
        <div className="cards">
          {data.map((item) => (
            <Servicecard
              imgsrc={item.imgsrc}
              heading={item.heading}
              para={item.para}
              number={item.number}
            />
          ))}
        </div>
      </section>
    </>
  );
}

export default Servicepagecards;

