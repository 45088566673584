import i18next from "i18next";
import globaleng from "../src/locales/en/translation.json";
import globalfrench from "../src/locales/fr/translation.json";
import globaldutch from "../src/locales/de/translation.json";
import globalarabic from "../src/locales/ar/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(LanguageDetector) // Add the language detector
  .init({
    interpolation: { escapeValue: false },
    resources: {
      en: { global: globaleng },
      ar: { global: globalarabic },
      fr: { global: globalfrench },
      de: { global: globaldutch },
    },
    fallbackLng: "en",
    ns: ["global"], // Define the namespaces used
    defaultNS: "global",
    detection: {
      order: ["localStorage", "navigator", "htmlTag"], // Check in localStorage first, then browser language, then HTML lang attribute
      caches: ["localStorage"], // Cache the user's choice in localStorage
    },
  });

export default i18next;
