import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import EffectCarousel from "./effect-carousel.esm";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "./swiper.css";
import team1 from "../../assets/team/1.webp";
import team2 from "../../assets/team/2.webp";
import team3 from "../../assets/team/3.webp";
import team4 from "../../assets/team/4.webp";
import team5 from "../../assets/team/5.webp";
import team6 from "../../assets/team/6.webp";
import team7 from "../../assets/team/7.webp";
import team8 from "../../assets/team/8.webp";
import team9 from "../../assets/team/9.webp";
import team10 from "../../assets/team/10.webp";
import team11 from "../../assets/team/11.webp";
import team12 from "../../assets/team/12.webp";
import team13 from "../../assets/team/13.webp";
import team14 from "../../assets/team/14.webp";
import team15 from "../../assets/team/15.webp";
import team16 from "../../assets/team/16.webp";
import { useTranslation } from "react-i18next";
const DayAtOctaloop = () => {
  const [index, setIndex] = useState(0);
  const {t} = useTranslation()

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <div className="day-at-octaloop">
        <h3 className="fw-bold text-center mb-5">
          {t('dayAtOctaloop.heading')}
        </h3>
        <p className="fw-medium text-center mb-5">
        {t('dayAtOctaloop.description')}
        </p>

        <Carousel activeIndex={index} onSelect={handleSelect} controls>
          <Carousel.Item>
            <div className="team-wrapper">
              <div className="team-img-wrapper">
                <img src={team1} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team2} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team3} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team4} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team5} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team6} alt="" />
              </div>

              <div className="team-img-wrapper">
                <img src={team7} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team8} alt="" />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="team-wrapper">
              <div className="team-img-wrapper">
                <img src={team9} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team10} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team11} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team12} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team13} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team14} alt="" />
              </div>

              <div className="team-img-wrapper">
                <img src={team15} alt="" />
              </div>
              <div className="team-img-wrapper">
                <img src={team16} alt="" />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default DayAtOctaloop;
